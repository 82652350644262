import React from "react"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

export default function AppMenu({ menuItems, buttonContent }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selected, setSelected] = React.useState(null)

  // let menuItems =
  // [
  //   { id: "1", name: "tanta" },
  //   { id: "12", name: "mahala" },
  // ]

  const handleClick = (event) => {
    // console.log(event.currentTarget)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e, id) => {
    console.log(id)
    console.log(e.currentTarget.innerText)
    setSelected(e.currentTarget.innerText)
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        {selected ? selected : buttonContent}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item) => (
          <MenuItem onClick={(e) => handleClose(e, item._id)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
