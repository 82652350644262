import React from "react"

const Home = () => {
  return (
    <>
      <img style={{ marginTop: "190px" }} src="./image7.png" alt="logo" />
      <p style={{ marginTop: "20px", color: "#474838", fontSize: "20px" }}>
        Welcome to laborex pharma!
      </p>
    </>
  )
}

export default Home
