import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import Paper from "@material-ui/core/Paper"
import { CircularProgress } from "@material-ui/core"
import { Edit, Delete, AddCircle, InsertDriveFile } from "@material-ui/icons"
import { Button, Divider } from "@material-ui/core"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import Grid from "@material-ui/core/Grid"

import Colors from "../Colors"
import AppModal from "./AppModal"

import {
  getProductsApi,
  addAllProductsApi,
  deleteProductApi,
  deleteAllProductApi,
} from "../Apis"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  ".MuiButton-root": {
    color: Colors.Red,
  },
  table: {
    minWidth: 650,
    backgroundColor: Colors.Green,
    color: Colors.PrimaryText,
    marginTop: "47px",
    "& .MuiTableCell-head": {
      color: Colors.PrimaryText,
    },
    " & .MuiTableCell-body": {
      color: Colors.SecondaryText,
    },
  },
  input: {
    color: Colors.BgPrimary,
  },
})

export default function Products() {
  const [open, setopen] = useState(false)
  const [openDelete, setopenDelete] = useState(false)
  const [openOffer, setopenOffer] = useState(false)
  const [page, setPage] = useState(1)
  const [rows, setrows] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [load, setload] = useState(false)
  const [file, setfile] = useState()
  const [loadDelete, setloadDelete] = useState(false)
  const [loadAddFile, setloadAddFile] = useState(false)
  const [currentId, setcurrentId] = useState()
  const [count, setcount] = useState()

  let ordersHeaders = ["", "الصنف "]

  let handleOrders = async () => {
    setload(true)
    let { data } = await getProductsApi(page, rowsPerPage)
    console.log(data)
    setcount(data.count)
    setrows(data.result)
    setload(false)
  }

  useEffect(() => {
    handleOrders()
  }, [rowsPerPage, page])

  let handleOpenModal = () => {
    setopen(true)
  }

  let handleCloseModal = () => {
    setopen(false)
  }
  let handleOpenModalOffer = () => {
    setopenOffer(true)
  }

  let handleCloseModalOffer = () => {
    setopenOffer(false)
  }

  let handleCloseModalDelete = () => {
    setopenDelete(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  let handleFile = (e) => {
    console.log(e.target.files[0])
    setfile(e.target.files[0])
  }

  let handleSendFile = async () => {
    if (file === undefined) {
      alert("please upload a file")
    } else {
      let data = new FormData()
      console.log(file)
      // data.append("id", lineId)
      data.append("excel", file)
      try {
        setloadAddFile(true)
        let result = await addAllProductsApi(data)
        console.log(result)
        handleOrders()
        setloadAddFile(false)
        handleCloseModalOffer()
      } catch (error) {
        console.log(error)
      }
    }
  }

  let handleDeleteProduct = async (id) => {
    console.log(id)
    setloadDelete(true)
    let result = await deleteProductApi(id)
    console.log(result)
    handleOrders()
    setloadDelete(false)
    handleCloseModalDelete()
    setfile()
  }

  let handleDeleteAll = async () => {
    setloadDelete(true)
    let result = await deleteAllProductApi()
    console.log(result)
    handleOrders()
    setloadDelete(false)
    handleCloseModal()
    setfile()
  }

  const classes = useStyles()

  return (
    <>
      {load ? (
        <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
      ) : (
        <>
          <Grid container style={{ marginTop: "70px" }}>
            <Grid item xs={6}>
              <Button
                className={classes[".MuiButton-root"]}
                onClick={handleOpenModal}
              >
                <Delete />
                مسح الكل
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={handleOpenModalOffer}>
                <AddCircle color="primary" fontSize="large" />
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {ordersHeaders.map((header) => (
                    <TableCell align="center">{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row) => (
                    <>
                      <TableRow key={row._id}>
                        <TableCell align="center">
                          <Delete
                            className={classes[".MuiButton-root"]}
                            onClick={() => {
                              setcurrentId(row._id)
                              setopenDelete(true)
                            }}
                          ></Delete>
                        </TableCell>

                        <TableCell align="center">{row.title}</TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            spacing={3}
            justify="flex-end"
            alignContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </>
      )}
      <AppModal
        Dialogwidth="md"
        title="Delete Offer"
        open={openDelete}
        handleClose={handleCloseModalDelete}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل أنت متأكد من الحذف؟
          </DialogContentText>
        </DialogContent>
        {loadDelete ? (
          <CircularProgress
            size="25px"
            style={{ marginLeft: "150px" }}
            color="inherit"
          />
        ) : (
          <DialogActions>
            <Button
              onClick={() => handleDeleteProduct(currentId)}
              color="primary"
              autoFocus
            >
              تأكيد
            </Button>
            <Button onClick={handleCloseModalDelete} color="primary">
              الغاء
            </Button>
          </DialogActions>
        )}
      </AppModal>
      <AppModal
        Dialogwidth="lg"
        // className={classes[".MuiDialog-paperWidthLg"]}
        title="Add Product"
        open={openOffer}
        handleClose={handleCloseModalOffer}
        style={{ height: "500px" }}
      >
        <DialogContent>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <input
                className={classes.input}
                type="file"
                accept=".xlsx, .xls, .csv"
                onChange={(e) => handleFile(e)}
              ></input>
            </Grid>
          </Grid>
        </DialogContent>

        {loadAddFile ? (
          <CircularProgress size="20px" style={{ marginLeft: "150px" }} />
        ) : (
          <DialogActions>
            <Button onClick={handleSendFile} color="primary" autoFocus>
              تأكيد
            </Button>
            <Button onClick={handleCloseModalOffer} color="primary">
              الغاء
            </Button>
          </DialogActions>
        )}
      </AppModal>

      <AppModal
        title="حذف الكل"
        open={open}
        handleClose={handleCloseModal}
        Dialogwidth="md"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل انت متأكد من الحذف؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteAll} color="primary" autoFocus>
            {/* <Button onClick={handleCloseModal} color="primary" autoFocus> */}
            تأكيد
          </Button>
          <Button onClick={handleCloseModal} color="primary">
            الغاء
          </Button>
        </DialogActions>
      </AppModal>
    </>
  )
}
