import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import Paper from "@material-ui/core/Paper"
import { Edit, Delete, AddCircle, InsertDriveFile } from "@material-ui/icons"
import { Button, Divider } from "@material-ui/core"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import Grid from "@material-ui/core/Grid"

import Colors from "../Colors"
import AppModal from "./AppModal"
import AppMenu from "./AppMenu"
import { getOrdersApi, DeleteOrderApi } from "../Apis"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  ".MuiButton-root": {
    color: Colors.Red,
  },
  table: {
    minWidth: 650,
    backgroundColor: Colors.Green,
    color: Colors.PrimaryText,
    marginTop: "47px",
    "& .MuiTableCell-head": {
      color: Colors.PrimaryText,
    },
    " & .MuiTableCell-body": {
      color: Colors.SecondaryText,
    },
  },
})
let lineItems = [
  { id: "1", name: "tanta" },
  { id: "12", name: "mahala" },
]

export default function Orders({ lineSearch, history }) {
  const [open, setopen] = useState(false)
  const [openDelete, setopenDelete] = useState(false)
  const [openOffer, setopenOffer] = useState(false)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [load, setload] = useState(false)
  const [load1, setload1] = useState(false)
  const [count, setcount] = useState()

  const [currId, setCurrId] = useState()

  const [rows, setrows] = useState([])
  const [rowsLength, setrowsLength] = useState(0)

  let ordersHeaders = [
    "",
    "الحالة",
    "رقم التليفون",
    " التاريخ ",
    "طريقة السداد",
    " الخط ",
    "العميل ",
  ]

  let handleOrders = async () => {
    setload(true)
    let result = await getOrdersApi(page, rowsPerPage)
    setcount(result.data.count)
    // console.log(result)
    setrows(result.data.result)
    setload(false)
  }

  useEffect(() => {
    handleOrders()

    const interval = setInterval(() => {
      handleOrders()
    }, 60000)

    return () => clearInterval(interval)
  }, [page, rowsPerPage])

  let handleCloseModalOffer = () => {
    setopenOffer(false)
  }

  let handleOpenDelete = (id) => {
    console.log(id)
    setCurrId(id)
    setopenDelete(true)
  }

  let handleCloseModalDelete = () => {
    setopenDelete(false)
  }

  const handleChangePage = (event, newPage) => {
    console.log("page", newPage)
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  let handleDeleteOrder = async (id) => {
    console.log(id)
    setload1(true)
    let result = await DeleteOrderApi(id)
    setload1(false)
    handleOrders()
    handleCloseModalDelete()
    console.log(result)
  }

  let handleChangeRoute = (id) => {
    history.push(`/client/${id}`)
  }

  const classes = useStyles()

  return (
    <>
      {load ? (
        <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {ordersHeaders.map((header) => (
                    <TableCell align="center">{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length &&
                  rows.map((row) => (
                    <>
                      <TableRow hover key={row._id}>
                        <TableCell
                          align="center"
                          onClick={() => {
                            return false
                          }}
                        >
                          <a>
                            <Delete
                              className={classes[".MuiButton-root"]}
                              onClick={() => handleOpenDelete(row._id)}
                            />
                          </a>
                        </TableCell>
                        {/* <article > */}
                        <TableCell
                          align="center"
                          onClick={() => handleChangeRoute(row._id)}
                        >
                          {row.status === "pending" ? "قيد المراجعة" : "تم"}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => handleChangeRoute(row._id)}
                        >
                          {row.clientId?.phone || "NOT FOUND"}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => handleChangeRoute(row._id)}
                        >
                          {new Date(row.date).toLocaleDateString("en-US")}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => handleChangeRoute(row._id)}
                        >
                          {row.plan === "weekly" ? "اسبوعي" : null}
                          {row.plan === "monthly" ? "شهري" : null}
                          {row.plan === "cash" ? "نقدي" : null}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => handleChangeRoute(row._id)}
                        >
                          {row.clientId?.line.name || "NOT FOUND"}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={() => handleChangeRoute(row._id)}
                        >
                          {row.clientId?.name || "NOT FOUND"}
                        </TableCell>
                        {/* </article> */}
                      </TableRow>
                    </>
                  ))}

                <AppModal
                  Dialogwidth="lg"
                  title=""
                  open={openDelete}
                  handleClose={handleCloseModalDelete}
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      هل انت متأكد من الحذف؟
                    </DialogContentText>
                  </DialogContent>
                  {load1 ? (
                    <DialogActions>
                      <CircularProgress
                        size="25px"
                        style={{ marginLeft: "160px" }}
                        color="inherit"
                      />
                    </DialogActions>
                  ) : (
                    <DialogActions>
                      <Button
                        // onClick={() => console.log(row._id)}
                        onClick={() => handleDeleteOrder(currId)}
                        color="primary"
                        autoFocus
                      >
                        تأكيد
                      </Button>
                      <Button onClick={handleCloseModalDelete} color="primary">
                        الغاء
                      </Button>
                    </DialogActions>
                  )}
                </AppModal>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            spacing={3}
            justify="flex-end"
            alignContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={8}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </>
      )}

      <AppModal
        Dialogwidth="lg"
        className={classes[".MuiDialog-paperWidthSm"]}
        title="Add Offer"
        open={openOffer}
        handleClose={handleCloseModalOffer}
      >
        <DialogContent>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={lineSearch ? 6 : 12}>
              <Button color="primary" variant="contained">
                <InsertDriveFile />
                <Divider orientation="vertical" flexItem />
                اضافة ملف
              </Button>
            </Grid>
            {lineSearch === "true" && (
              <Grid item xs={6}>
                <AppMenu
                  menuItems={lineItems}
                  buttonContent="اسم الخط"
                ></AppMenu>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalOffer} color="primary" autoFocus>
            تأكيد
          </Button>
          <Button onClick={handleCloseModalOffer} color="primary">
            الغاء
          </Button>
        </DialogActions>
      </AppModal>
    </>
  )
}
