import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

const Logout = (props) => {
  const history = useHistory()

  //   console.log(props.history)
  useEffect(() => {
    localStorage.removeItem("token")
    localStorage.removeItem("usertoken")
    localStorage.removeItem("role")
    history.push("/")
    window.location.reload()
    //   props.history.push("/")
  }, [])

  return null
}

export default Logout
