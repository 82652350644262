import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { AddCircle, Delete, InsertDriveFile } from "@material-ui/icons"
import {
  Button,
  TextField,
  Container,
  DialogActions,
  CircularProgress,
  Grid,
  Snackbar,
} from "@material-ui/core"
import ImageUploading from "react-images-uploading"
import AppCard from "./AppCard"
import AppModal from "../AppModal"
import MuiAlert from "@material-ui/lab/Alert"

import { getServicesApi, addServicesApi } from "../../Apis"
import Colors from "../../Colors"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  media: {
    height: 140,
  },
  btnClass: {
    marginTop: "70px",
    // marginLeft: "265px",
  },
  btnClass1: {
    marginTop: "70px",
    color: Colors.Red,
  },
  ".MuiContainer-root": {
    textAlign: "right",
    "& .MuiInputLabel-formControl": {
      top: "-10px !important",
      right: "15px !important",
    },

    "& .MuiAlert-root": {
      backgroundColor: Colors.Green,
      color: Colors.PrimaryText,
    },
  },
  snackBar: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function MediaCard() {
  const classes = useStyles()
  const [images, setImages] = useState([])
  const [open, setOpen] = useState(false)
  const [services, setservices] = useState([])
  const [load1, setload1] = useState(false)
  const [load, setload] = useState(false)
  const [serviceTitle, setserviceTitle] = useState("")
  const [openmodal, setopenmodal] = useState(false)
  const [success, setsuccess] = useState(false)

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList)
  }

  let handleChange = (e) => {
    console.log(e.target.value)
    setserviceTitle(e.target.value)
  }

  let handleGetServices = async () => {
    setload(true)
    let { data } = await getServicesApi()
    setservices(data)
    console.log(data)
    setload(false)
  }
  let handleOpen = () => {
    setOpen(true)
  }

  let handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    handleGetServices()
  }, [])

  let handleAddService = async (img) => {
    if (images.length === 0) {
      alert("please upload an image")
    } else {
      let _data_body = new FormData()

      _data_body.append("disc", serviceTitle)
      _data_body.append("img", img.file)
      console.log(img.file)

      try {
        setload1(true)
        let result = await addServicesApi(_data_body)
        console.log(result)
        handleGetServices()
        setload1(false)
        setsuccess(true)
        handleClose()
      } catch (err) {
        console.log(err.response)
        setload1(false)
      }
    }
  }

  return (
    <>
      {load ? (
        <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
      ) : (
        <>
          <Grid
            container
            spacing={3}
            justify="flex-end"
            alignContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={6}>
              <Button className={classes.btnClass} onClick={handleOpen}>
                <AddCircle color="primary" fontSize="large" />
              </Button>
            </Grid>
          </Grid>
          <div className={classes.root}>
            {services.map((cardItem) => (
              <AppCard
                id={cardItem._id}
                img={cardItem.img}
                title={cardItem.disc}
                key={cardItem._id}
                getData={handleGetServices}
              ></AppCard>
            ))}
          </div>
          <AppModal title="Add Service" open={open} handleClose={handleClose}>
            <Container
              className={classes[".MuiContainer-root"]}
              maxWidth="lg"
              style={{ padding: "20px 50px" }}
            >
              <ImageUploading
                value={images}
                onChange={onChange}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  <>
                    <div className="upload__image-wrapper">
                      <Button
                        color={isDragging ? "secondary" : "primary"}
                        variant="contained"
                        size="large"
                        style={{ marginBottom: "20px" }}
                        onClick={onImageUpload}
                        {...dragProps}
                        startIcon={<InsertDriveFile />}
                      >
                        اضافة صورة
                      </Button>

                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image["data_url"]} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button onClick={() => onImageUpdate(index)}>
                              تحديث
                            </button>
                            <button onClick={() => onImageRemove(index)}>
                              حذف
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>

                    <br></br>

                    <TextField
                      color="primary"
                      id="filled-multiline-static"
                      label="اضافة عنوان"
                      multiline
                      rows={4}
                      onChange={(e) => handleChange(e)}
                      variant="filled"
                      // style={{ paddingBottom: "20px" }}
                    />
                    {load1 ? (
                      <div>
                        <CircularProgress
                          style={{ marginRight: "90px", marginTop: "10px" }}
                          size="1.5rem"
                          color="primary"
                        />
                      </div>
                    ) : (
                      <DialogActions style={{ textAlign: "right" }}>
                        <Button
                          onClick={() => handleAddService(images[0])}
                          color="primary"
                          autoFocus
                        >
                          تأكيد
                        </Button>
                        <Button onClick={handleClose} color="primary">
                          الغاء
                        </Button>
                      </DialogActions>
                    )}
                  </>
                )}
              </ImageUploading>
            </Container>
          </AppModal>
        </>
      )}

      {success && (
        <Snackbar
          open={success}
          autoHideDuration={10000}
          onClose={() => setsuccess(false)}
        >
          <Alert
            onClose={() => setsuccess(false)}
            severity="success"
            color="success"
          >
            Service Added Successfully
          </Alert>
        </Snackbar>
      )}
    </>
  )
}
