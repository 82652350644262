import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { Delete } from "@material-ui/icons"
import {
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  Backdrop,
} from "@material-ui/core"

import { deleteServiceApi } from "../../Apis"
import Colors from "../../Colors"
import AppModal from "../AppModal"
import { getDefaultNormalizer } from "@testing-library/react"

const useStyles = makeStyles({
  root: {
    margin: "10px",
    marginTop: 70,
    width: 345,
    "& .MuiButton-root": {
      color: Colors.Red,
    },
  },
  media: {
    height: 300,
  },
})

export default function AppCard({ img, title, id, getData }) {
  const classes = useStyles()
  const [open, setopen] = useState(false)
  const [openZoom, setOpenZoom] = useState(false)
  const [load, setload] = useState(false)

  let handleClose = () => {
    setopen(false)
  }

  let handleDeleteService = async (id) => {
    setload(true)
    let result = await deleteServiceApi(id)
    console.log(result)
    setload(false)
    getData()
    handleClose()
  }

  let handleCloseModal = () => {
    setOpenZoom(false)
  }

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            onClick={() => setOpenZoom(true)}
            className={classes.media}
            image={"/" + img}
            title="Offer Card"
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small">
            <Delete onClick={() => setopen(true)} />
          </Button>
        </CardActions>
      </Card>

      <AppModal open={open} title="Delete Service" handleClose={handleClose}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل انت متأكد من الحذف؟
          </DialogContentText>
        </DialogContent>

        {load ? (
          <CircularProgress
            color="inherit"
            size="30px"
            style={{ marginLeft: "150px" }}
          />
        ) : (
          <DialogActions>
            <Button
              onClick={() => handleDeleteService(id)}
              color="primary"
              autoFocus
            >
              تأكيد
            </Button>
            <Button onClick={handleClose} color="primary">
              الغاء
            </Button>
          </DialogActions>
        )}
      </AppModal>

      <AppModal open={openZoom} handleClose={handleCloseModal}>
        <img src={"/" + img} alt="" width="500px" height="530px" />
      </AppModal>
    </>
  )
}
