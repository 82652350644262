import "./App.css"
import React, { useEffect, useState } from "react"
import { Route, Switch, Redirect } from "react-router-dom"

import AppForm from "./components/AppForm"
import AppTable from "./components/AppTable"
import Container from "./components/Container"
import Services from "./components/Services/Services"
import SideNav from "./components/SideNav"
import Orders from "./components/Orders"
import Lines from "./components/Lines/"
import LineTable from "./components/Lines/LineTable"
import Products from "./components/Products"
import NewIncoming from "./components/NewIncoming"
import TelesalesTable from "./components/Telesales/TelesalesTable"
import Client from "./components/Telesales/Client"
import Logout from "./components/logout"
import Home from "./components/home"

function App() {
  const discountHeaders = ["", "نسبة الخصم", "الصنف"]
  const productHeaders = ["", "الصنف"]

  const [token, setToken] = useState(localStorage.getItem("token"))

  const [userToken, setusertoken] = useState(localStorage.getItem("usertoken"))

  const [role, setrole] = useState(localStorage.getItem("role"))

  let loadUser = () => {
    const token = localStorage.getItem("token")
    const usertoken = localStorage.getItem("usertoken")
    const role = localStorage.getItem("role")
    setToken(token)
    setusertoken(usertoken)
    setrole(role)
    console.log(token)
    console.log("user token", usertoken)
  }
  useEffect(() => {
    if (userToken || token) loadUser()
  }, [userToken, token])

  const rows = [
    { id: 1, name: "comtrix", discount: "15%" },
    { id: 12, name: "comtrix", discount: "15%" },
    { id: 123, name: "comtrix", discount: "15%" },
    { id: 1234, name: "comtrix", discount: "15%" },
  ]

  const productrows = [
    { id: 1, name: "comtrix" },
    { id: 12, name: "comtrix" },
    { id: 123, name: "comtrix" },
    { id: 1234, name: "comtrix" },
  ]

  return (
    <div className="App">
      <Container
        bgColor="#F1F3C4"
        containerwidth="100%"
        // containerheight="100%"
        style={{ marginTop: "70px" }}
      >
        {!userToken && !token ? (
          <Route
            exact
            path="/"
            render={(routeProps) => (
              <AppForm
                setToken={setToken}
                setrole={setrole}
                setUserToken={setusertoken}
                {...routeProps}
              />
            )}
          />
        ) : (
          <SideNav title="LaborexPharma">
            <Switch>
              <Route
                exact
                path="/"
                render={(routeProps) => {
                  if (!token) return <Redirect to="/" />
                  return <Home />
                }}
              />
              <Route
                exact
                path="/discounts"
                render={(routeProps) => {
                  if (!token) return <Redirect to="/" />
                  return (
                    <AppTable
                      discountheaders={discountHeaders}
                      rows={rows}
                      lineSearch="true"
                      {...routeProps}
                    />
                  )
                }}
              />
              <Route
                exact
                path="/line/:id"
                render={(routeProps) => {
                  if (!token) return <Redirect to="/" />
                  return <LineTable {...routeProps} />
                }}
              />

              <Route
                exact
                path="/orders"
                render={(routeProps) => {
                  if (!token) return <Redirect to="/" />
                  return (
                    <Orders
                      discountheaders={productHeaders}
                      rows={productrows}
                      lineSearch="false"
                      {...routeProps}
                    />
                  )
                }}
              />

              <Route
                exact
                path="/products"
                render={(routeProps) => {
                  if (!token) return <Redirect to="/" />
                  return (
                    <Products
                      discountheaders={productHeaders}
                      rows={productrows}
                      lineSearch="false"
                      {...routeProps}
                    />
                  )
                }}
              />
              <Route
                exact
                path="/telesalesdata"
                render={(routeProps) => {
                  if (!userToken) return <Redirect to="/orders" />
                  return (
                    <TelesalesTable
                      discountheaders={productHeaders}
                      rows={productrows}
                      lineSearch="false"
                      {...routeProps}
                    />
                  )
                }}
              />
              <Route
                exact
                path="/client/:id"
                render={(routeProps) => {
                  // if (!userToken) return <Redirect to="/" />
                  return <Client rows={productrows} {...routeProps} />
                }}
              />

              <Route
                exact
                path="/newIncoming"
                render={(routeProps) => {
                  if (!token) return <Redirect to="/" />
                  return (
                    <NewIncoming
                      discountheaders={productHeaders}
                      rows={productrows}
                      lineSearch="true"
                      {...routeProps}
                    />
                  )
                }}
              />
              <Route
                exact
                path="/services"
                render={(routeProps) => {
                  if (!token) return <Redirect to="/" />
                  return <Services {...routeProps} />
                }}
              />
              {/* <Route
                exact
                path="/telesales"
                render={(routeProps) => {
                  if (!token) return <Redirect to="/" />
                  return (
                    <TeleSales
                      // telesales={true}
                      discountheaders={telesalesheaders}
                      rows={telesalesrows}
                      // lineSearch="false"
                      {...routeProps}
                    />
                  )
                }}
              /> */}
              <Route
                exact
                path="/lines"
                render={(routeProps) => {
                  if (!token) return <Redirect to="/" />
                  return <Lines {...routeProps} />
                }}
              />
              <Route
                exact
                path="/logout"
                render={(routeProps) => <Logout {...routeProps} />}
              />
              {/* <Route
                exact
                path="/"
                render={(routeProps) => <AppForm {...routeProps} />}
              /> */}
              <Route
                path="*"
                render={() => (
                  <h1 style={{ marginTop: "70px" }}>Error not found!!!</h1>
                )}
              />
            </Switch>
          </SideNav>
        )}
      </Container>
    </div>
  )
}

export default App
