import React, { useState, useEffect } from "react"
import {
  Button,
  Container,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  CircularProgress,
  Snackbar,
} from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"

import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import { Delete, AddCircle } from "@material-ui/icons"
import Colors from "../../Colors"
import AppModal from "../AppModal"
import { getLinesApi, deleteLineApi, AddLineApi } from "../../Apis"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Lines = () => {
  const useStyles = makeStyles(() => ({
    root: {
      marginTop: "70px",
      backgroundColor: Colors.Green,
      padding: "30px",
      "& .MuiButton-contained": {
        color: Colors.BgPrimary,
        backgroundColor: Colors.BgSecondary,
        marginBottom: "15px",
        marginLeft: "15px",
      },
      "& .MuiButton-contained:hover": {
        backgroundColor: Colors.BgPrimary,
        color: Colors.PrimaryText,
      },
      "& .MuiTypography-h5": {
        color: Colors.PrimaryText,
        marginBottom: "20px",
      },
      "& .MuiTypography-h6": {
        color: Colors.PrimaryText,
        // marginBottom: "20px",
      },
    },
    "MuiIcon-root": {
      color: Colors.Red,
    },
    AddIcon: {
      color: Colors.PrimaryText,
      marginLeft: "90%",
    },
  }))

  const [open, setopen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [lines, setlines] = useState([])
  const [openAddLine, setOpenAddLine] = useState(false)
  const [load, setload] = useState(false)
  const [loadDelete, setloadDelete] = useState(false)
  const [deleteLineError, setdeleteLineError] = useState(false)
  const [currentId, setcurrentId] = useState()

  const [linename, setlinename] = useState("")

  let handleGetLines = async () => {
    setload(true)
    let result = await getLinesApi()
    setlines(result.data)
    setload(false)
    console.log(result)
  }

  useEffect(() => {
    handleGetLines()
  }, [])

  let handleClose = () => {
    setopen(false)
  }

  let handleCloseModal = () => {
    setOpenModal(false)
  }

  let handleCloseAddLine = () => {
    setOpenAddLine(false)
  }

  let handleDeleteLine = async (id) => {
    console.log(id)
    setloadDelete(true)
    let result = await deleteLineApi(id)
    console.log(result)
    setloadDelete(false)
    if (result.data === "this line has users") {
      setdeleteLineError(true)
    }
    handleGetLines()
    handleCloseModal()
  }
  let handleAddLine = async () => {
    console.log(linename)
    const result = await AddLineApi(linename)
    console.log(result)
    handleGetLines()
    handleCloseAddLine()
  }

  const classes = useStyles()
  return (
    <>
      <Container className={classes.root}>
        {load ? (
          <CircularProgress color="inherit" />
        ) : (
          <>
            <div>
              <Typography variant="h5" align="center">
                الخطوط الحالية
              </Typography>

              {lines.map((line) => (
                <>
                  <div>
                    <Delete
                      className={classes["MuiIcon-root"]}
                      onClick={() => {
                        setOpenModal(true)
                        setcurrentId(line._id)
                      }}
                    />
                    <Link
                      to={`/line/${line._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        key={line._id}
                        variant="contained"
                        size="large"
                        style={{
                          backgroundColor: "#f1f3c4",
                          width: "110px",
                          height: "45px",
                        }}
                      >
                        {line.name}
                      </Button>
                    </Link>
                  </div>
                </>
              ))}

              <Typography align="right">
                <Button
                  variant="contained"
                  endIcon={<AddCircle />}
                  onClick={() => setOpenAddLine(true)}
                >
                  اضافة خط
                </Button>
              </Typography>
            </div>
          </>
        )}
      </Container>

      <AppModal
        title="اضافة خط"
        open={openAddLine}
        handleClose={handleCloseAddLine}
        Dialogwidth="md"
      >
        <DialogContent style={{ padding: "10px 30px", textAlign: "right" }}>
          <div>
            <TextField
              id="filled-required"
              label="ادخل اسم خط"
              variant="filled"
              onChange={(e) => setlinename(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddLine} color="primary" autoFocus>
            تأكيد
          </Button>
          <Button onClick={handleCloseAddLine} color="error">
            الغاء
          </Button>
        </DialogActions>
      </AppModal>
      <AppModal
        title="حذف خط"
        open={openModal}
        handleClose={handleCloseModal}
        Dialogwidth="md"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل انت متأكد من الحذف؟
          </DialogContentText>
        </DialogContent>
        {loadDelete ? (
          <DialogActions>
            <CircularProgress
              style={{ marginRight: "150px" }}
              color="inherit"
              size="20px"
            />
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={() => handleDeleteLine(currentId)}
              color="primary"
              autoFocus
            >
              تأكيد
            </Button>
            <Button onClick={handleCloseModal} color="error">
              الغاء
            </Button>
          </DialogActions>
        )}
      </AppModal>
      {deleteLineError && (
        <Snackbar
          open={deleteLineError}
          autoHideDuration={4000}
          onClose={() => setdeleteLineError(false)}
        >
          <Alert
            onClose={() => setdeleteLineError(false)}
            severity="error"
            color="error"
          >
            هذا الخط له مستخدمون،يرجى الاتصال بالدعم
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default Lines
