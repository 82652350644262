import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import Paper from "@material-ui/core/Paper"
import { CircularProgress, Snackbar } from "@material-ui/core"
import { Button, Typography } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import MuiAlert from "@material-ui/lab/Alert"

import Grid from "@material-ui/core/Grid"
import { getOneOrderApi, confirmTelesales } from "../../Apis"

import Colors from "../../Colors"
import { ArrowBack } from "@material-ui/icons"
import { useHistory } from "react-router-dom"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  ".MuiButton-root": {
    color: Colors.Red,
  },
  table: {
    minWidth: 650,
    backgroundColor: Colors.Green,
    color: Colors.PrimaryText,
    marginTop: "47px",
    "& .MuiTableCell-head": {
      color: Colors.PrimaryText,
    },
    " & .MuiTableCell-body": {
      color: Colors.SecondaryText,
    },
  },
  snackBar: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

export default function Client({ match }) {
  const [load, setload] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [clientName, setclientName] = useState("")
  const [orderDate, setOrderDate] = useState("")
  const [plan, setPlan] = useState("")
  const [lineName, setlineName] = useState("")
  const [addtele, setAddtele] = useState(false)

  let headers = ["الكمية", "الصنف"]
  const [rows, setrows] = useState([])

  const history = useHistory()

  let handleGetData = async () => {
    let id = match.params.id
    setload(true)
    let { data } = await getOneOrderApi(id)
    console.log(data)
    setclientName(data.clientId.name)
    setOrderDate(data.date)
    setPlan(data.plan)
    setlineName(data.lineId.name)
    console.log(data)
    setrows(data.products)
    setload(false)
  }
  useEffect(() => {
    handleGetData()
  }, [])

  let addTelesales = async () => {
    let id = match.params.id
    console.log(id)
    let result = await confirmTelesales(id, "done")
    console.log(result.data)
    setAddtele(true)
  }

  let goBack = () => {
    history.goBack()
  }

  const classes = useStyles()

  return (
    <>
      {load ? (
        <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <Grid
                    container
                    spacing={2}
                    justify="flex-start"
                    alignContent="flex-end"
                    alignItems="center"
                    style={{ paddingTop: "70px" }}
                  >
                    <Grid item xs={3}>
                      <Typography>
                        {" "}
                        طريقة الدفع: {plan === "weekly" ? "اسبوعي" : null}
                        {plan === "monthly" ? "شهري" : null}
                        {plan === "cash" ? "نقدي" : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        التاريخ:{" "}
                        {new Date(orderDate).toLocaleDateString("en-US")}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>العميل: {clientName}</Typography>
                    </Grid>
                    {localStorage.getItem("token") && (
                      <Grid item xs={3}>
                        <Typography>الخط: {lineName}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </TableRow>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell align="center">{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell align="center">{row.quantity}</TableCell>
                    <TableCell align="center">{row.title}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            spacing={3}
            justify="flex-end"
            alignContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={6}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                color="primary"
                variant="contained"
                onClick={() => goBack()}
                startIcon={<ArrowBackIcon />}
                style={{ marginTop: "10px" }}
              >
                رجوع{" "}
              </Button>
            </Grid>
            {localStorage.getItem("usertoken") && (
              <Grid item xs={6}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  variant="contained"
                  onClick={() => addTelesales()}
                  style={{ marginTop: "10px" }}
                >
                  تأكيد
                </Button>
              </Grid>
            )}
          </Grid>
          {addtele && (
            <Snackbar
              open={addtele}
              autoHideDuration={3000}
              onClose={() => setAddtele(false)}
            >
              <Alert
                onClose={() => setAddtele(false)}
                severity="error"
                color="error"
              >
                !تمت العملية بنجاح
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </>
  )
}
