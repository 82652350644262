let Colors = {
  PrimaryText: "#FFFFFF",
  SecondaryText: "#D4CCCC",
  BgPrimary: "#474838",
  BgSecondary: "#f1f3c4",
  Red: "#E56262",
  Green: "#13743A",
}

export default Colors
