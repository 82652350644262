import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import Tooltip from "@material-ui/core/Tooltip"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import Paper from "@material-ui/core/Paper"
import { CircularProgress } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { getTelesalesApi } from "../../Apis"
import Colors from "../../Colors"
import { Button } from "@material-ui/core"

const useStyles = makeStyles({
  row: {
    cursor: "pointer",
  },
  root: {
    flexGrow: 1,
    "pointer-events": "auto",
  },

  ".MuiButton-root": {
    color: Colors.Red,
  },
  table: {
    cursor: "cursor",
    minWidth: 650,
    backgroundColor: Colors.Green,
    color: Colors.PrimaryText,
    marginTop: "47px",
    "& .MuiTableCell-head": {
      color: Colors.PrimaryText,
    },
    " & .MuiTableCell-body": {
      color: Colors.SecondaryText,
    },
    " & .MuiTableRow-root": {
      cursor: "pointer",
    },
    "& .MuiTableRow-hover": {
      cursor: "pointer",
    },
  },
  hover: {
    "&:hover": {
      cursor: `url(
        "https://www.google.com/imgres?imgurl=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Ffb%2F95%2F33%2Ffb9533f53f35f8e7dfe9bc0c5eae30a8.jpg&imgrefurl=https%3A%2F%2Fwww.pinterest.com%2Fpin%2F665336544929972779%2F&tbnid=RLnOqPFW6K2S6M&vet=12ahUKEwjSmaH6jeLvAhWIwYUKHfAGAwwQMygBegUIARDLAQ..i&docid=xP96tcF6yh20LM&w=960&h=800&q=image%20cursor&ved=2ahUKEwjSmaH6jeLvAhWIwYUKHfAGAwwQMygBegUIARDLAQ",
      )`,
    },
  },
})
let lineItems = [
  { id: "1", name: "tanta" },
  { id: "12", name: "mahala" },
]

export default function TelesalesTable({ history }) {
  const [load, setload] = useState(false)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [rows, setrows] = useState([])
  const [count, setcount] = useState([])

  let handleGetData = async () => {
    setload(true)
    let result = await getTelesalesApi(page, rowsPerPage)
    console.log(result)
    setrows(result.data.result)
    setcount(result.data.count)
    setload(false)
    console.log(rows)
  }
  useEffect(() => {
    handleGetData()
  }, [rowsPerPage, page])

  let headers = [
    "رقم التليفون",
    " التاريخ ",
    "طريقة السداد",
    " الخط ",
    "العميل ",
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }
  let handleChangeRoute = (id) => {
    history.push(`/client/${id}`)
  }

  const classes = useStyles()

  return (
    <>
      {load ? (
        <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell align="center">{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row) => (
                    <TableRow
                      classes={{ hover: classes.hover, root: classes.row }}
                      hover
                      key={row._id}
                      onClick={() => handleChangeRoute(row._id)}
                    >
                      <TableCell align="center">{row.clientId.phone}</TableCell>

                      <TableCell align="center">
                        {new Date(row.date).toLocaleDateString("en-US")}
                      </TableCell>
                      <TableCell align="center">
                        {row.plan === "weekly" ? "اسبوعي" : null}
                        {row.plan === "monthly" ? "شهري" : null}
                        {row.plan === "cash" ? "نقدي" : null}
                      </TableCell>
                      <TableCell align="center">
                        {row.clientId.line.name}
                      </TableCell>

                      <TableCell align="center">{row.clientId.name}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            spacing={3}
            justify="flex-end"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={8}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
