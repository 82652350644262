import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import Paper from "@material-ui/core/Paper"
import {
  Edit,
  Delete,
  AddCircle,
  InsertDriveFile,
  PersonAdd,
} from "@material-ui/icons"
import {
  Button,
  Divider,
  TableFooter,
  Typography,
  TextField,
} from "@material-ui/core"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import Grid from "@material-ui/core/Grid"
import { CircularProgress } from "@material-ui/core"
import { useForm } from "react-hook-form"

import Colors from "../../Colors"
import AppModal from "../AppModal"
import AppMenu from "../AppMenu"
import {
  getLineApi,
  addClientApi,
  deleteLineClientApi,
  addTeleApi,
} from "../../Apis"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    "& .MuiInputLabel-root": {
      top: "0 !important",
      right: "270px !important",
      color: Colors.Red,
    },
  },
  ".MuiInputLabel-root": {
    color: Colors.Red,
  },
  ".MuiButton-root": {
    color: Colors.Red,
  },
  ".MuiInputBase-input": {
    color: Colors.BgSecondary,
  },
  table: {
    minWidth: 650,
    backgroundColor: Colors.Green,
    color: Colors.PrimaryText,
    marginTop: "47px",
    "& .MuiTableCell-head": {
      color: Colors.PrimaryText,
    },
    " & .MuiTableCell-body": {
      color: Colors.SecondaryText,
    },
  },
})

let discountheaders = ["", "رقم التليفون", "العميل"]

export default function LineTable(props) {
  const [open, setopen] = useState(false)
  const [openDelete, setopenDelete] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [openclientform, setopenclientform] = useState(false)
  const [data, setdata] = useState([])
  const [load, setload] = useState(false)
  const [loadDelete, setloadDelete] = useState(false)
  const [rows, setrows] = useState([])
  const [lineName, setlineName] = useState()
  const [currentId, setcurrentId] = useState()
  const [notunique, setnotunique] = useState(false)
  const [openTele, setopenTele] = useState(false)

  let handleGetData = async () => {
    let id = props.match.params.id
    console.log(id)
    let { data } = await getLineApi(id)
    console.log("before", data)

    let newdata = data.slice(0, data.length - 1)
    let linename = data.slice(data.length - 1, data.length)[0]
    setlineName(linename.lineName.name)

    setrows(newdata)
  }

  const { register, handleSubmit, errors } = useForm()
  const onSubmit = async (data, e) => {
    setload(true)
    console.log(data)
    let id = props.match.params.id
    const result = await addClientApi({
      userName: data.userName,
      password: data.password,
      name: data.name,
      phone: data.phone,
      line: id,
      role: "user",
    })
    if (result.data === "this user name already exists") {
      setload(false)
      return setnotunique(true)
    }
    setload(false)
    handleCloseModal()
    console.log(result)
    e.target.reset()
    setopenclientform(false)
    handleGetData()
  }
  const addTele = async (data, e) => {
    setload(true)
    console.log(data)
    let id = props.match.params.id
    const result = await addTeleApi({
      userName: data.userName,
      password: data.password,
      line: id,
      role: "tele",
    })
    if (result.data === "this tele already exists") {
      setload(false)
      return setnotunique(true)
    }
    setload(false)
    handleCloseModalTele()
    console.log(result)
    e.target.reset()
  }

  useEffect(() => {
    handleGetData()
  }, [])

  let handleCloseModal = () => {
    setopen(false)
  }
  let handleCloseModalTele = () => {
    setopenTele(false)
  }

  let handleOpenDelete = () => {
    setopenDelete(true)
  }

  let handleCloseModalDelete = () => {
    setopenDelete(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  let handleDeleteProduct = async (id) => {
    setloadDelete(true)

    let result = await deleteLineClientApi(id)
    console.log(result)
    setloadDelete(false)
    handleCloseModalDelete()
    handleGetData()
  }

  const classes = useStyles()

  return (
    <>
      <TableContainer component={Paper}>
        <Typography style={{ marginTop: "65px" }} variant="h5">
          خط {lineName}
        </Typography>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {discountheaders.map((header) => (
                <TableCell align="center">{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <>
                <TableRow key={row._id}>
                  <TableCell align="center">
                    <Delete
                      className={classes[".MuiButton-root"]}
                      onClick={() => {
                        setopenDelete(true)
                        setcurrentId(row._id)
                      }}
                    ></Delete>
                  </TableCell>

                  <TableCell align="center">{row.phone}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                </TableRow>
              </>
            ))}
            <TableRow>
              <TableCell align="center">
                <Grid
                  container
                  spacing={3}
                  justifyContent="flex-end"
                  alignContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <PersonAdd
                      className={classes["AddIcon"]}
                      onClick={() => setopen(true)}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Grid
                  container
                  spacing={3}
                  justifyContent="flex-end"
                  alignContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={12} color="PrimaryText">
                    <Button
                      color="Green"
                      variant="contained"
                      onClick={() => setopenTele(true)}
                      endIcon={<PersonAdd className={classes["AddIcon"]} />}
                    >
                      اضافة تلي سيلز
                    </Button>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        container
        spacing={3}
        justify="flex-end"
        alignContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={8}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
      <AppModal
        Dialogwidth="md"
        title=""
        open={openDelete}
        handleClose={handleCloseModalDelete}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل أنت متأكد من الحذف؟
          </DialogContentText>
        </DialogContent>
        {loadDelete ? (
          <CircularProgress
            style={{ marginTop: "75px" }}
            color="primary"
            size="2rem"
          />
        ) : (
          <DialogActions>
            <Button
              onClick={() => handleDeleteProduct(currentId)}
              color="primary"
              autoFocus
            >
              تأكيد
            </Button>
            <Button onClick={handleCloseModalDelete} color="primary">
              الغاء
            </Button>
          </DialogActions>
        )}
      </AppModal>
      <AppModal
        title="اضافة عميل"
        open={open}
        handleClose={handleCloseModal}
        Dialogwidth="md"
      >
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextField
                variant="filled"
                style={{
                  width: "100%",
                  padding: "10px",
                }}
                id="standard-required"
                label="اسم العميل"
                name="name"
                type="text"
                required
                inputRef={register}
              />
              <span style={{ color: Colors.Red }}>
                {errors.name && "name must be min 5 chars long"}
              </span>
              <TextField
                variant="filled"
                style={{ width: "100%", padding: "10px" }}
                id="standard-password-input"
                label="كلمة المرور"
                name="password"
                type="password"
                inputRef={register}
                required
              />
              <TextField
                variant="filled"
                style={{ width: "100%", padding: "10px" }}
                id="standard-required-code"
                label="كود العميل"
                name="userName"
                // inputRef={register}
                inputRef={register({
                  minLength: { value: 5, message: "error message" },
                })}
                required
              />
              <span style={{ color: Colors.Red }}>
                {" "}
                {errors.userName && "username must be at least 5 chars long"}
                {<br></br>}
                {notunique && "username must be unique"}
              </span>
              <TextField
                variant="filled"
                style={{ width: "100%", padding: "10px" }}
                id="standard-required-phone"
                label="رقم الهاتف"
                name="phone"
                // min={11}
                // max={11}
                // InputProps={{ min: 11, max: 11 }}
                inputRef={register({
                  minLength: { value: 11, message: "error message" },
                  maxLength: { value: 11 },
                })}
                type="number"
                required
              />
              <span style={{ color: Colors.Red }}>
                {" "}
                {errors.phone &&
                  "phone must be at least & no more than 11 characters long"}
              </span>
            </div>
            {load ? (
              <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
            ) : (
              <DialogActions>
                <Button
                  style={{ marginTop: "10%" }}
                  color={Colors.BgSecondary}
                  type="submit"
                >
                  تأكيد
                </Button>
                <Button
                  style={{ marginTop: "10%" }}
                  color={Colors.BgSecondary}
                  type="submit"
                  onClick={handleCloseModal}
                >
                  الغاء
                </Button>
              </DialogActions>
            )}
          </form>
        </DialogContent>
      </AppModal>
      <AppModal
        title="اضافة تلي سيلز"
        open={openTele}
        handleClose={handleCloseModalTele}
        Dialogwidth="md"
      >
        <DialogContent>
          <form onSubmit={handleSubmit(addTele)}>
            <div>
              <TextField
                variant="filled"
                style={{ width: "100%", padding: "10px" }}
                id="standard-required-code"
                label="كود العميل"
                name="userName"
                // inputRef={register}
                inputRef={register({
                  minLength: { value: 5, message: "error message" },
                })}
                required
              />
              <span style={{ color: Colors.Red }}>
                {" "}
                {errors.userName && "username must be at least 5 chars long"}
                {<br></br>}
                {notunique && "username must be unique"}
              </span>
              <TextField
                variant="filled"
                style={{ width: "100%", padding: "10px" }}
                id="standard-password-input"
                label="كلمة المرور"
                name="password"
                type="password"
                inputRef={register}
                required
              />
            </div>
            {load ? (
              <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
            ) : (
              <DialogActions>
                <Button
                  style={{ marginTop: "10%" }}
                  color={Colors.BgSecondary}
                  type="submit"
                >
                  تأكيد
                </Button>
                <Button
                  style={{ marginTop: "10%" }}
                  color={Colors.BgSecondary}
                  type="submit"
                  onClick={handleCloseModalTele}
                >
                  الغاء
                </Button>
              </DialogActions>
            )}
          </form>
        </DialogContent>
      </AppModal>
    </>
  )
}
