import React, { useState, useEffect } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import Paper from "@material-ui/core/Paper"
import { Delete, AddCircle, ArrowDropDown } from "@material-ui/icons"
import { Button, CircularProgress } from "@material-ui/core"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import Grid from "@material-ui/core/Grid"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

import {
  addProductsApi,
  getLinesApi,
  getNewIncomingApi,
  deleteNewIncomingApi,
  deleteAllNewIncomingApi,
} from "../Apis"

import Colors from "../Colors"
import AppModal from "./AppModal"
import AppMenu from "./AppMenu"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  ".MuiButton-root": {
    color: Colors.Red,
  },
  table: {
    minWidth: 650,
    backgroundColor: Colors.Green,
    color: Colors.PrimaryText,
    marginTop: "47px",
    "& .MuiTableCell-head": {
      color: Colors.PrimaryText,
    },
    " & .MuiTableCell-body": {
      color: Colors.SecondaryText,
    },
  },
})

export default function NewIncoming({ discountheaders }) {
  const [load, setload] = useState(false)
  const [open, setopen] = useState(false)
  const [openDelete, setopenDelete] = useState(false)
  const [openOffer, setopenOffer] = useState(false)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selected, setSelected] = React.useState("")
  const [lineId, setlineId] = useState()
  const [lines, setlines] = useState([])
  const [file, setfile] = useState()
  const [load1, setload1] = useState(false)
  const [lineId1, setlineId1] = useState("")
  const [rows, setrows] = useState([])
  const [loadDelete, setloadDelete] = useState(false)
  const [currentId, setcurrentId] = useState()
  const [count, setcount] = useState(0)

  let handleGetLines = async () => {
    let { data } = await getLinesApi()
    setlineId1(data[0]._id)
    setlineId(data[0]._id)
    setSelected(data[0].name)
    console.log("line id", lineId1)
    console.log(data)
    setlines(data)
  }

  let handleGetData = async () => {
    setload(true)
    let { data } = await getNewIncomingApi(lineId1, page, rowsPerPage)
    setload(false)
    setcount(data.count)
    setrows(data.result)
    console.log(data)
  }

  useEffect(() => {
    handleGetLines()
  }, [])

  useEffect(() => {
    return lineId1 ? handleGetData() : null
  }, [lineId1, rowsPerPage, page])

  let handleOpenModalOffer = () => {
    setopenOffer(true)
  }

  let handleOpenModal = () => {
    setopen(true)
  }
  let handleCloseModal = () => {
    setopen(false)
  }

  let handleCloseModalOffer = () => {
    setopenOffer(false)
  }

  let handleOpenDelete = () => {
    setopenDelete(true)
  }

  let handleCloseModalDelete = () => {
    setopenDelete(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  const handleClick = (event) => {
    // console.log(event.currentTarget)
    setAnchorEl(event.currentTarget)
  }
  const handleClick1 = (event) => {
    // console.log(event.currentTarget)
    setAnchorEl(event.currentTarget)
  }

  // const handleClose = (e, id) => {
  //   console.log(id)

  //   setlineId(id)
  //   console.log(e.currentTarget.innerText)
  //   setSelected(e.currentTarget.innerText)
  //   setAnchorEl(null)
  // }

  const handleClose1 = (e, id) => {
    console.log(id)

    setlineId1(id)
    console.log(e.currentTarget.innerText)
    setSelected(e.currentTarget.innerText)
    setAnchorEl(null)
  }

  let handleFile = (e) => {
    console.log(e.target.files[0])
    setfile(e.target.files[0])
  }

  let handleSendFile = async () => {
    if (file === undefined) {
      alert("please upload a file")
    } else {
      console.log("lineId", lineId1)
      let data = new FormData()

      // data.append("id", lineId)
      data.append("excel", file)
      data.append("id", lineId1)

      try {
        setload1(true)
        let result = await addProductsApi(data)
        console.log(result)
        setload1(false)
        handleCloseModalOffer()
        handleGetData()
      } catch (error) {
        setload1(false)
      }
    }
  }
  let handleDeleteProduct = async (id) => {
    // console.log(id)
    console.log("lineId1", lineId1)
    setloadDelete(true)
    let result = await deleteNewIncomingApi(lineId1, id)
    console.log(result)
    setloadDelete(false)
    handleGetData()
    handleCloseModalDelete()
    setfile()
  }

  let handleDeleteAll = async () => {
    setloadDelete(true)
    let result = await deleteAllNewIncomingApi(lineId1)
    console.log(result)
    setloadDelete(false)
    handleGetData()
    handleCloseModal()
    setfile()
  }

  const classes = useStyles()

  return (
    <>
      {load ? (
        <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
      ) : (
        <>
          <Grid container style={{ marginTop: "75px" }}>
            <Grid item xs={6}>
              <Button
                className={classes[".MuiButton-root"]}
                onClick={handleOpenModal}
              >
                <Delete />
                مسح الكل
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={handleOpenModalOffer}>
                <AddCircle color="primary" fontSize="large" />
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {discountheaders.map((header) => (
                    <TableCell align="center">{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <>
                    <TableRow key={row._id}>
                      <TableCell align="center">
                        <Delete
                          className={classes[".MuiButton-root"]}
                          onClick={() => {
                            setopenDelete(true)
                            setcurrentId(row._id)
                          }}
                        ></Delete>
                      </TableCell>

                      <TableCell align="center">{row.title}</TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            spacing={3}
            justify="flex-end"
            alignContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={6}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
            <Grid item xs={6}>
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  variant="contained"
                  onClick={handleClick1}
                  startIcon={<ArrowDropDown />}
                  style={{ marginTop: "10px" }}
                >
                  {selected ? selected : "اسم الخط"}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose1}
                >
                  {lines.map((item) => (
                    <MenuItem onClick={(e) => handleClose1(e, item._id)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      <AppModal
        Dialogwidth="md"
        title=""
        open={openDelete}
        handleClose={handleCloseModalDelete}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل أنت متأكد من الحذف؟
          </DialogContentText>
        </DialogContent>
        {loadDelete ? (
          <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
        ) : (
          <DialogActions>
            <Button
              onClick={() => {
                handleDeleteProduct(currentId)
              }}
              color="primary"
              autoFocus
            >
              تأكيد
            </Button>
            <Button onClick={handleCloseModalDelete} color="primary">
              الغاء
            </Button>
          </DialogActions>
        )}
      </AppModal>
      <AppModal
        Dialogwidth="lg"
        className={classes[".MuiDialog-paperWidthSm"]}
        title="اضافة وارد"
        open={openOffer}
        handleClose={handleCloseModalOffer}
      >
        <DialogContent>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={7}>
              <input
                type="file"
                accept=".xlsx, .xls, .csv"
                onChange={handleFile}
              />
            </Grid>

            {/* <Grid item xs={5}>
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  variant="contained"
                  onClick={handleClick}
                  startIcon={<ArrowDropDown />}
                >
                  {selected ? selected : "اسم الخط"}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {lines.map((item) => (
                    <MenuItem onClick={(e) => handleClose(e, item._id)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Grid> */}
          </Grid>
        </DialogContent>
        {load1 ? (
          <div>
            <CircularProgress
              style={{ paddingBottom: "10px", marginLeft: "180px" }}
              size="1.5rem"
              color="primary"
            />
          </div>
        ) : (
          <DialogActions>
            <Button onClick={handleSendFile} color="primary" autoFocus>
              تأكيد
            </Button>
            <Button onClick={handleCloseModalOffer} color="primary">
              الغاء
            </Button>
          </DialogActions>
        )}
      </AppModal>
      <AppModal
        title="حذف الكل"
        open={open}
        handleClose={handleCloseModal}
        Dialogwidth="md"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل أنت متأكد من الحذف؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteAll} color="primary" autoFocus>
            {/* <Button onClick={handleCloseModal} color="primary" autoFocus> */}
            تأكيد
          </Button>
          <Button onClick={handleCloseModal} color="primary">
            الغاء
          </Button>
        </DialogActions>
      </AppModal>

      {/* LINE DROPDOWN */}
    </>
  )
}
