import axios from "axios"
import { ownerToken, userToken } from "./Auth"

let backend = "https://orders.ebdaa-business.com"
// let backend = "https://laborex-pharma.herokuapp.com"
let ownerUrl = backend + "/owner"
let userUrl = backend + "/user"

//login
export function loginApi(req) {
  let result = axios.post(backend + "/login", req)
  return result
}

//get orders
export function getOrdersApi(page, size) {
  let result = axios.get(backend + `/getAllOrders?page=${page}&limit=${size}`, {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//deleteOrder
export function DeleteOrderApi(id) {
  let result = axios.delete(backend + `/deleteOrder?id=${id}`, {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//get services
export async function getServicesApi() {
  let result = await axios.get(ownerUrl + "/getservices", {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}
//add service
export async function addServicesApi(obj) {
  let result = await axios.post(ownerUrl + "/addService", obj, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//delete service
export function deleteServiceApi(id) {
  console.log(localStorage.getItem("token"))
  let result = axios.delete(
    ownerUrl + `/deleteservice?id=${id}`,

    {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    },
  )
  return result
}

//get lines
export async function getLinesApi() {
  let result = await axios.get(ownerUrl + "/getLines", {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//get signle line
// adminLineProducts

// TODO get line clients
export async function getLineApi(id) {
  let result = await axios.get(backend + `/getLineClients?id=${id}`, {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//add line
export function AddLineApi(name) {
  let result = axios.post(
    ownerUrl + "/addLine",
    { name },
    {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    },
  )
  return result
}

// delete line
export function deleteLineApi(id) {
  let result = axios.delete(userUrl + `/deleteLine?id=${id}`, {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//delete line product
export function deleteLineClientApi(id) {
  let result = axios.delete(backend + `/deleteClient?id=${id}`, {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//get discounts
export async function getDiscountsApi(page, size, line) {
  let result = await axios.get(
    backend + `/getDiscounts?page=${page}&limit=${size}&line=${line}`,
    {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    },
  )
  return result
}

//get telesales
export async function getTelesalesApi(page, size) {
  let result = await axios.get(
    backend + `/pendingOrders?page=${page}&limit=${size}`,
    {
      headers: {
        "x-auth-token": localStorage.getItem("usertoken"),
      },
    },
  )
  return result
}

//reserve telesales

export async function confirmTelesales(id, status) {
  let result = await axios.put(
    backend + "/changeStatus",
    { id, status },
    {
      headers: {
        "x-auth-token": localStorage.getItem("usertoken"),
      },
    },
  )
  return result
}

//get one order
export async function getOneOrderApi(id) {
  let result = await axios.get(backend + `/getOneOrder?id=${id}`, {
    headers: {
      "x-auth-token": localStorage.getItem("token")
        ? localStorage.getItem("token")
        : localStorage.getItem("usertoken"),
    },
  })
  return result
}

//delete client from telesales
export async function deleteClientApi(id) {
  let result = await axios.delete(
    ownerUrl + "/deleteClient",
    { id },
    {
      headers: {
        "x-auth-token": localStorage.getItem("usertoken"),
      },
    },
  )
  return result
}

//get products
export async function getProductsApi(page, limit) {
  let result = await axios.get(
    ownerUrl + `/getProducts?page=${page}&limit=${limit}`,
    {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    },
  )
  return result
}

//delete signle product
export async function deleteProductApi(id) {
  let result = await axios.delete(ownerUrl + `/deleteProduct?id=${id}`, {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//delete all products
export async function deleteAllProductApi() {
  let result = await axios.delete(backend + "/deleteAllProducts", {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//add products (our products)
export async function addAllProductsApi(obj) {
  let result = await axios.post(backend + "/addProductExcel", obj, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//add line products
export async function addProductsApi(obj) {
  let result = await axios.post(ownerUrl + "/addLineProducts", obj, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//get new incoming data
export async function getNewIncomingApi(id, page, size) {
  let result = await axios.get(
    backend + `/adminLineProducts?line=${id}&page=${page}&limit=${size}`,
    {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    },
  )
  return result
}

//add discount excel
export async function addDiscountApi(obj) {
  console.log(localStorage.getItem("token"))
  let result = await axios.post(backend + "/addDiscountExcel", obj, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

//delete discount product
export async function deleteDiscountApi(id, prodId) {
  let result = await axios.delete(
    backend + `/deleteDiscountItem?lineId=${id}&productId=${prodId}`,
    {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    },
  )
  return result
}

//delete all discounts
export async function deleteAllDiscountsApi(id) {
  let result = await axios.delete(backend + `/deleteAllDiscount?lineId=${id}`, {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

export async function deleteNewIncomingApi(id, prodId) {
  let result = await axios.delete(
    backend + `/deleteLineProduct?lineId=${id}&productId=${prodId}`,
    {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    },
  )
  return result
}

//delete all new incoming

export async function deleteAllNewIncomingApi(id) {
  let result = await axios.delete(
    backend + `/deleteAllLineProducts?lineId=${id}`,
    {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    },
  )
  return result
}

// client admin signup

export async function addClientApi(obj) {
  let result = await axios.post(backend + "/admin/signUp", obj, {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}

// telesales signup

export async function addTeleApi(obj) {
  let result = await axios.post(backend + "/admin/signUp", obj, {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  })
  return result
}
