import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom"

import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import CssBaseline from "@material-ui/core/CssBaseline"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import { Menu, ChevronLeft, ChevronRight, ExitToApp } from "@material-ui/icons"
import ListItem from "@material-ui/core/ListItem"
import Colors from "../Colors"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { Button } from "@material-ui/core"
import Logout from "./logout"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "70px",
    display: "flex",
    "& .MuiAppBar-colorPrimary": {
      backgroundColor: Colors.BgSecondary,
    },
    "& .MuiIconButton-colorInherit": {
      color: Colors.Green,
    },
    "& .MuiPaper-root": {
      color: Colors.BgPrimary,
      backgroundColor: Colors.BgSecondary,
    },
    "& .makeStyles-content-9": {
      marginBottom: "47px",
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: Colors.BgPrimary,
      color: Colors.BgSecondary,
    },
  },
  activeLink: {
    backgroundColor: Colors.BgPrimary,
    color: Colors.BgSecondary,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}))

export default function PersistentDrawerRight({ title, children }) {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Button component={NavLink} to="/">
            <img src="./logoWide.jpeg" alt="logo" width="165px" height="71px" />
          </Button>
          <Typography variant="h6" noWrap className={classes.title}>
            {title}
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {children}
      </main>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            disabled={localStorage.getItem("usertoken") == null ? false : true}
            component={NavLink}
            to="/orders"
            activeClassName={classes.activeLink}
          >
            الطلبيات
          </ListItem>
          <ListItem
            button
            disabled={localStorage.getItem("usertoken") == null ? false : true}
            component={NavLink}
            to="/discounts"
            activeClassName={classes.activeLink}
          >
            الخصومات
          </ListItem>
          <ListItem
            button
            disabled={localStorage.getItem("usertoken") == null ? false : true}
            component={NavLink}
            to="/products"
            activeClassName={classes.activeLink}
          >
            منتجاتنا
          </ListItem>
          <ListItem
            button
            disabled={localStorage.getItem("usertoken") == null ? false : true}
            component={NavLink}
            to="/newIncoming"
            activeClassName={classes.activeLink}
          >
            الوارد الجديد
          </ListItem>
          <ListItem
            button
            disabled={localStorage.getItem("usertoken") == null ? false : true}
            component={NavLink}
            to="/services"
            activeClassName={classes.activeLink}
          >
            خدماتنا
          </ListItem>
          <ListItem
            button
            disabled={localStorage.getItem("usertoken") == null ? true : false}
            component={NavLink}
            to="/telesalesdata"
            activeClassName={classes.activeLink}
          >
            تيلي سيلز
          </ListItem>
          <ListItem
            button
            disabled={localStorage.getItem("usertoken") == null ? false : true}
            component={NavLink}
            to="/lines"
            activeClassName={classes.activeLink}
          >
            الخطوط
          </ListItem>
        </List>
        <Divider />
        <List>
          {["تسجيل الخروج"].map((text, index) => (
            <ListItem button component={Link} to="/logout" key={text}>
              <ListItemText primary={text} />
              <ListItemIcon>
                {index % 2 === 0 ? <ExitToApp /> : <ExitToApp />}
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  )
}
