import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { makeStyles } from "@material-ui/core/styles"
import {
  TextField,
  Button,
  CircularProgress,
  Backdrop,
  Snackbar,
} from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import Colors from "../Colors"
import { loginApi } from "../Apis"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
    width: "420px",
    height: "65vh",
    margin: "auto",
    backgroundColor: Colors.Green,

    borderRadius: "5px",
    "& .MuiFormLabel-root.Mui-focused": {
      right: "-230px !important",
    },
    "& .MuiInputLabel-formControl": {
      top: "0 !important",
      right: "-230px !important",

      color: Colors.SecondaryText,
      "&:focus": {
        right: "-230px !important",
      },
    },
    "& .MuiInputBase-root": {
      color: Colors.PrimaryText,
    },
    "& .MuiButton-contained": {
      backgroundColor: Colors.BgSecondary,
    },

    "& .MuiAlert-root": {
      backgroundColor: Colors.Red,
      color: "black",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  snackBar: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

const AppForm = ({ history, setToken, setrole, setUserToken }) => {
  const [load, setload] = useState(false)
  const [error, seterror] = useState(false)

  const classes = useStyles()
  const { register, handleSubmit } = useForm()

  const onSubmit = async (data, e) => {
    setload(true)
    try {
      let result = await loginApi({
        userName: data.userName,
        password: data.password,
      })
      console.log(result)
      // setload(false)
      if (result.status === 200) {
        if (result.data.role === "owner") {
          localStorage.setItem("token", result.data.token)
          localStorage.setItem("role", result.data.role)
          let mytoken = localStorage.getItem("token")
          let myrole = localStorage.getItem("role")
          console.log(mytoken)
          setToken(mytoken)
          setrole(myrole)
          setload(false)
          history.push("/orders")
        } else {
          localStorage.setItem("usertoken", result.data.token)
          localStorage.setItem("role", result.data.role)
          let mytoken = localStorage.getItem("usertoken")
          let myrole = localStorage.getItem("role")
          console.log("user token login", mytoken)
          setrole(myrole)
          setUserToken(mytoken)
          setload(false)
          if (mytoken) history.push("/telesalesdata")
        }
      }
    } catch (error) {
      setload(false)
      console.log(error)
      seterror(true)
    }
  }

  return (
    <article style={{ paddingTop: "13vh", height: "83vh" }}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <img
          src="./loginlogo.jpg"
          alt="logo"
          style={{
            width: "200px",
            height: "200px",
            paddingTop: "0px",
            marginTop: "-1px",
            borderRadius: "10px",
          }}
        />
        <p variant="p" style={{ color: Colors.PrimaryText }}>
          تسجيل الدخول
        </p>
        <TextField
          style={{
            width: "80%",
            padding: "10px",
          }}
          id="standard-required"
          label="اسم المستخدم"
          name="userName"
          inputRef={register}
          required
        />

        <TextField
          style={{ width: "80%", padding: "10px" }}
          id="standard-password-input"
          label="كلمة المرور"
          name="password"
          type="password"
          inputRef={register}
          required
        />

        <Button
          style={{ marginTop: "10%" }}
          variant="contained"
          color={Colors.BgSecondary}
          type="submit"
        >
          تسجيل الدخول
        </Button>
        {load && (
          <Backdrop
            className={classes.backdrop}
            open={load}
            onClick={() => setload(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {error && (
          <Snackbar
            open={error}
            autoHideDuration={4000}
            onClose={() => seterror(false)}
          >
            <Alert
              onClose={() => seterror(false)}
              severity="error"
              color="error"
            >
              !اسم المستخدم او كلمة المرور خطأ
            </Alert>
          </Snackbar>
        )}
      </form>
    </article>
  )
}

export default AppForm
