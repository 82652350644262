const Container = ({ bgColor, containerwidth, containerheight, children }) => {
  return (
    <article
      style={{
        backgroundColor: bgColor,
        width: containerwidth,
        height: containerheight,
      }}
    >
      {children}
    </article>
  )
}

export default Container
