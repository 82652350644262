import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TablePagination from "@material-ui/core/TablePagination"
import Paper from "@material-ui/core/Paper"
import { CircularProgress } from "@material-ui/core"
import { Delete, AddCircle, ArrowDropDown } from "@material-ui/icons"
import { Button } from "@material-ui/core"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import Grid from "@material-ui/core/Grid"
import Menu from "@material-ui/core/Menu"
import {
  getDiscountsApi,
  deleteDiscountApi,
  getLinesApi,
  addDiscountApi,
  deleteAllDiscountsApi,
} from "../Apis"
import MenuItem from "@material-ui/core/MenuItem"
import Colors from "../Colors"
import AppModal from "./AppModal"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  ".MuiButton-root": {
    color: Colors.Red,
  },
  table: {
    minWidth: 450,
    backgroundColor: Colors.Green,
    color: Colors.PrimaryText,
    marginTop: "20px",
    "& .MuiTableCell-head": {
      color: Colors.PrimaryText,
    },
    " & .MuiTableCell-body": {
      color: Colors.SecondaryText,
    },
  },
})

export default function BasicTable({ discountheaders, lineSearch, telesales }) {
  const [load, setload] = useState(false)
  const [load1, setload1] = useState(false)
  const [open, setopen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openDelete, setopenDelete] = useState(false)
  const [openOffer, setopenOffer] = useState(false)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [rows, setrows] = useState([])
  const [count, setcount] = useState()
  const [lines, setlines] = useState([])
  const [lineId, setlineId] = useState()
  const [lineId1, setlineId1] = useState()
  const [file, setfile] = useState()
  const [currId, setCurrId] = useState()
  const [loadDelete, setloadDelete] = useState(false)
  const [selected, setSelected] = React.useState("طنطا")

  let handleGetLines = async () => {
    try {
      let { data } = await getLinesApi()

      console.log(data)
      setlines(data)
      if (data) {
        // setlineId(data[0]._id)
        setlineId1(data[0]._id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  let handleFile = (e) => {
    setfile(e.target.files[0])
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  let handleSendFile = async () => {
    if (file === undefined) {
      alert("يجب رفع ملف")
    } else {
      let data = new FormData()
      data.append("excel", file)
      data.append("id", lineId1)

      try {
        setload1(true)
        let result = await addDiscountApi(data)
        setload1(false)
        handleGetData()
        handleCloseModalOffer()
      } catch (error) {
        setload1(false)
      }
    }
  }

  // const handleClose = (e, id) => {
  //   console.log(id)
  //   setlineId(id)
  //   setSelected(e.currentTarget.innerText)
  //   setAnchorEl(null)
  // }

  const handleClose1 = (e, id) => {
    console.log(id)

    setlineId1(id)
    setSelected(e.currentTarget.innerText)
    setAnchorEl(null)
  }

  let handleGetData = async () => {
    setload(true)
    let { data } = await getDiscountsApi(page, rowsPerPage, lineId1)
    setrows(data.result)
    setcount(data.count)
    setload(false)
  }

  useEffect(() => {
    handleGetLines()
  }, [])

  useEffect(() => {
    if (lineId1) {
      handleGetData()
    }
  }, [lineId1, page, rowsPerPage, lineId])

  let handleOpenModal = () => {
    setopen(true)
  }
  let handleCloseModal = () => {
    setopen(false)
  }
  let handleOpenModalOffer = () => {
    setopenOffer(true)
  }

  let handleCloseModalOffer = () => {
    setopenOffer(false)
  }

  let handleOpenDelete = (id) => {
    setCurrId(id)
    setopenDelete(true)
  }

  let handleCloseModalDelete = () => {
    setopenDelete(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  let handleDeleteDiscount = async (id) => {
    setloadDelete(true)
    const result = await deleteDiscountApi(lineId1, id)
    setloadDelete(false)
    handleGetData()
    handleCloseModalDelete()
    setfile()
  }

  let handleDeleteAll = async () => {
    setloadDelete(true)
    const result = await deleteAllDiscountsApi(lineId1)
    setloadDelete(false)
    handleGetData()
    handleCloseModal()
    setfile()
  }

  const classes = useStyles()

  return (
    <>
      {load ? (
        <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
      ) : (
        <>
          <Grid container>
            <Grid item xs={6}>
              <Button
                // variant="outlined"
                className={classes[".MuiButton-root"]}
                style={{ marginTop: "70px" }}
                onClick={handleOpenModal}
              >
                <Delete />
                مسح الكل
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleOpenModalOffer}
                style={{ marginRight: "10px", marginTop: "70px" }}
              >
                <AddCircle color="primary" fontSize="large" />
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {discountheaders.map((header) => (
                    <TableCell align="center">{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows &&
                  rows.map((row) => (
                    <>
                      <TableRow key={row._id}>
                        <TableCell align="center">
                          <Delete
                            className={classes[".MuiButton-root"]}
                            onClick={() => handleOpenDelete(row._id)}
                          ></Delete>
                        </TableCell>

                        <TableCell align="center">{row.discount}%</TableCell>
                        <TableCell align="center">{row.title}</TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            spacing={3}
            justify="flex-end"
            alignContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={8}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>

            <Grid item xs={4}>
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  variant="contained"
                  onClick={handleClick}
                  startIcon={<ArrowDropDown />}
                  style={{ marginTop: "10px" }}
                >
                  {selected ? selected : "اسم الخط"}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose1}
                >
                  {lines &&
                    lines.map((item) => (
                      <MenuItem onClick={(e) => handleClose1(e, item._id)}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Menu>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      <AppModal
        Dialogwidth="md"
        title=""
        open={openDelete}
        handleClose={handleCloseModalDelete}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل أنت متأكد من الحذف؟
          </DialogContentText>
        </DialogContent>
        {loadDelete ? (
          <CircularProgress style={{ marginTop: "75px" }} color="inherit" />
        ) : (
          <DialogActions>
            <Button
              onClick={() => handleDeleteDiscount(currId)}
              color="primary"
              autoFocus
            >
              تأكيد
            </Button>
            <Button onClick={handleCloseModalDelete} color="primary">
              الغاء
            </Button>
          </DialogActions>
        )}
      </AppModal>

      <AppModal
        Dialogwidth="lg"
        className={classes[".MuiDialog-paperWidthSm"]}
        title="اضافة ملف خصم"
        open={openOffer}
        handleClose={handleCloseModalOffer}
      >
        <DialogContent>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={6}>
              <input
                type="file"
                accept=".xlsx, .xls, .csv"
                onChange={handleFile}
              />
            </Grid>

            {/* <Grid item xs={6}>
              <div>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  variant="contained"
                  onClick={handleClick}
                  startIcon={<ArrowDropDown />}
                >
                  {selected ? selected : "اسم الخط"}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  
                  {lines &&
                    lines.map((item) => (
                      <MenuItem onClick={(e) => handleClose(e, item._id)}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Menu>
              </div>
            </Grid> */}
          </Grid>
        </DialogContent>
        {load1 ? (
          <div>
            <CircularProgress
              style={{ paddingBottom: "10px", marginLeft: "180px" }}
              size="1.5rem"
              color="primary"
            />
          </div>
        ) : (
          <DialogActions>
            <Button onClick={handleSendFile} color="primary" autoFocus>
              تأكيد
            </Button>
            <Button onClick={handleCloseModalOffer} color="primary">
              الغاء
            </Button>
          </DialogActions>
        )}
      </AppModal>
      <AppModal
        title="Delete All"
        open={open}
        handleClose={handleCloseModal}
        Dialogwidth="md"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            هل انت متأكد من حذف جميع الخصومات؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteAll} color="primary" autoFocus>
            تأكيد
          </Button>
          <Button onClick={handleCloseModal} color="primary">
            الغاء
          </Button>
        </DialogActions>
      </AppModal>
    </>
  )
}
