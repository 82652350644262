import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"

import { blue } from "@material-ui/core/colors"

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  paperWidthMd: {
    minWidth: "600px",
  },
})
let wrapper = React.createRef()

export default function AppModal({
  title,
  children,
  open,
  handleClose,
  Dialogwidth,
}) {
  const classes = useStyles()
  return (
    <Dialog
      maxWidth={Dialogwidth}
      className={classes[".MuiDialog-paperWidthLg"]}
      ref={wrapper}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      {children}
    </Dialog>
  )
}
